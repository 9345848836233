// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as Checkbox from "../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardGeneralInformationScss from "./ProjectWizardGeneralInformation.scss";
import * as ProjectWizardGeneralInformation_FieldValidationMessage from "./ProjectWizardGeneralInformation_FieldValidationMessage.res.js";

var css = ProjectWizardGeneralInformationScss;

function ProjectWizardGeneralInformation_FlagsSection(props) {
  var updateFlag = props.updateFlag;
  var acceptedTermsAndConditions = props.acceptedTermsAndConditions;
  var tmp;
  tmp = props.userRole === "Agent" ? true : false;
  var tmp$1;
  tmp$1 = props.projectId !== undefined ? (
      props.projectStatus === "Draft" ? JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Checkbox.make, {
                      id: "project-general-info-terms-and-conditions",
                      size: "LG",
                      checked: acceptedTermsAndConditions,
                      onChange: (function ($$event) {
                          updateFlag("TermsAndConditions", $$event.target.checked);
                        })
                    }),
                JsxRuntime.jsxs(Label.make, {
                      forId: "project-general-info-terms-and-conditions",
                      children: [
                        "I agree to the ",
                        JsxRuntime.jsx("a", {
                              children: "Terms & Conditions",
                              href: "/terms-of-use",
                              target: "_blank"
                            })
                      ]
                    })
              ],
              className: css.primaryCheckBoxContainer
            }) : null
    ) : JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(Checkbox.make, {
                  id: "project-general-info-terms-and-conditions",
                  size: "LG",
                  checked: acceptedTermsAndConditions,
                  onChange: (function ($$event) {
                      updateFlag("TermsAndConditions", $$event.target.checked);
                    })
                }),
            JsxRuntime.jsxs(Label.make, {
                  forId: "project-general-info-terms-and-conditions",
                  children: [
                    "I agree to the ",
                    JsxRuntime.jsx("a", {
                          children: "Terms & Conditions",
                          href: "/terms-of-use",
                          target: "_blank"
                        })
                  ]
                })
          ],
          className: css.primaryCheckBoxContainer
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Checkbox.make, {
                              id: "project-general-info-isAgent",
                              size: "LG",
                              checked: props.isAgent,
                              visuallyDisabled: tmp,
                              onChange: (function ($$event) {
                                  updateFlag("Agent", $$event.target.checked);
                                })
                            }),
                        JsxRuntime.jsx(Label.make, {
                              forId: "project-general-info-isAgent",
                              children: "I am an agent working on behalf of a client"
                            })
                      ],
                      className: css.primaryCheckBoxContainer
                    }),
                tmp$1,
                JsxRuntime.jsx(ProjectWizardGeneralInformation_FieldValidationMessage.make, {
                      status: props.termsAndConditionsValidationStatus
                    })
              ]
            });
}

var make = ProjectWizardGeneralInformation_FlagsSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
