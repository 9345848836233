// extracted by mini-css-extract-plugin
export var column = "UserColocationRfpProjectWizardTooltip__column__BUG2w";
export var flex = "UserColocationRfpProjectWizardTooltip__flex__pB_8s";
export var flexColumn = "UserColocationRfpProjectWizardTooltip__flexColumn__AuEKn";
export var gap1 = "UserColocationRfpProjectWizardTooltip__gap1__PTGfM";
export var gap2 = "UserColocationRfpProjectWizardTooltip__gap2__iL7J1";
export var gap3 = "UserColocationRfpProjectWizardTooltip__gap3___4IHO";
export var gap4 = "UserColocationRfpProjectWizardTooltip__gap4__t3I7x";
export var gap5 = "UserColocationRfpProjectWizardTooltip__gap5___CxWo";
export var icon = "UserColocationRfpProjectWizardTooltip__icon__xZmWl";
export var iconCenterLeftTooltip = "UserColocationRfpProjectWizardTooltip__iconCenterLeftTooltip__TA3v2";
export var iconCenterRightTooltip = "UserColocationRfpProjectWizardTooltip__iconCenterRightTooltip__ZMWvB";
export var iconFineRightTooltip = "UserColocationRfpProjectWizardTooltip__iconFineRightTooltip__r7IEQ";
export var iconLeftTooltip = "UserColocationRfpProjectWizardTooltip__iconLeftTooltip__GcLIO";
export var iconRightTooltip = "UserColocationRfpProjectWizardTooltip__iconRightTooltip__DOoVJ";
export var info = "UserColocationRfpProjectWizardTooltip__info__fQ1gd";
export var row = "UserColocationRfpProjectWizardTooltip__row__rDDaN";
export var tooltipPositionContainer = "UserColocationRfpProjectWizardTooltip__tooltipPositionContainer__QwyVH";